import styled from 'styled-components'
import { breakpoints } from 'globalStyles'

const TrackingStyle = styled.div`
  display: table;
  vertical-align: top;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 2px 8px 4px;
  @media only screen and ${breakpoints.desktop} {
    font-size: 16px;
  }
`
const VendorStyle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  @media only screen and ${breakpoints.tablet} {
    font-size: 16px;
  }
  @media only screen and ${breakpoints.desktop} {
    font-size: 12px;
    line-height: 16px;
  }
`

const LogoStyle = styled.div`
  .imageRight {
    height: 51px;
    width: 44px;
    padding: '8px 12px';
  }
  @media only screen and ${breakpoints.tablet} {
    .imageRight {
      height: 62px;
    }
  }
  @media only screen and ${breakpoints.desktop} {
    .imageRight {
      height: 62px;
      width: 54px;
    }
  }
`

export { TrackingStyle, VendorStyle, LogoStyle }
