import { React } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'
import FAIcon from 'reusableComponents/FAIcon'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { getDOMEncodeString } from 'utils/helperFunctions'
import FilledRing from 'assets/svg/filled_ring.svg'
import EmptyCircle from 'assets/svg/empty_circle.svg'

const circleTypeHandler = (circleType) => {
  const checkmarkCircle = <FAIcon icon={faCircleCheck} color={colors.primary_green} />

  switch (circleType) {
    case 1:
      return EmptyCircle
    case 2:
      return FilledRing
    default:
      return getDOMEncodeString(checkmarkCircle)
  }
}

const HistoryElement = styled.div`
  .historyDate {
    width: 30%;
    float: left;
    padding: 13px 5px 10px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    background: ${colors.primary_white};
  }

  @media ${breakpoints.sm_above} {
    .historyDate {
      width: 35%;
      font-size: 16px;
      padding-right: 8px;
    }
  }

  .historyText{
    width: 65%;
    @media ${breakpoints.sm_above} {
      width: 60%;
    }
    float: left;
    padding: ${(props) => (props.circleType === 1 ? '0' : '10px 0px 10px 10px')};
    text-align: left;
    background: ${colors.primary_white};
    margin-left: 5px;
    border-left: 3px solid ${colors.primary_green};
    position: relative;F
  }

  .historyText:before {
    border-right: 5px solid ${colors.primary_white};
    content: '';
    height: ${(props) => (props.circleType === 1 ? '28px' : '6px')};
    left: -3.5px;
    top: 18px;
    position: absolute;
  }

  &:first-child .historyText:before {
    border-right: 5px solid ${colors.primary_white};
    content: '';
    height: 28px;
    left: -3.5px;
    top: 0;
    position: absolute;
  }

  &:first-child .historyText:after {
    content: url(${(props) => circleTypeHandler(props.circleType)});
    position: absolute;
    top: 12px;
    left: -8px;
    width: 10px;
    height: 10px;
  }

  &:last-child .historyText{
    margin-bottom: 15px;
  }

  .historyText:after {
    content: url(${(props) => circleTypeHandler(props.circleType)});
    position: absolute;
    top: 10px;
    left: -7px;
    width: 10px;
    height: 10px;
  }
`

const History = ({ circleType, dateInfo, descriptionInfo }) => {
  return (
    <HistoryElement circleType={circleType}>
      <div className="historyDate">{dateInfo}</div>
      <div className="historyText">{descriptionInfo}</div>
    </HistoryElement>
  )
}

History.propTypes = {
  descriptionInfo: PropTypes.node.isRequired,
  circleType: PropTypes.oneOf([0, 1, 2]).isRequired,
  dateInfo: PropTypes.string,
}

History.defaultProps = {
  dateInfo: '',
}

export default History
