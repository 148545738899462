import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OutlineButton } from 'reusableComponents/Buttons/Buttons'
import GearIcon from 'assets/svg/gear.svg'
import { breakpoints, colors } from 'globalStyles'
import { selectIsUserVerified } from 'store/shipping/shippingSelectors'
import PreferencesSummary from 'components/PreferencesSummary'
import Verification from 'components/Verification'

const PreferencesContainer = styled.div`
  display: none;
  justify-content: space-between;
  @media ${breakpoints.desktop} {
    border-top: 1.5px solid ${colors.primary_blue};
    border-left: 1.5px solid ${colors.primary_blue};
    border-bottom: 1.5px solid ${colors.primary_blue};
    padding: 10px 0px 10px 0px;
    border-radius: 8px 0px 0px 8px;
    width: 140px;
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 15px;
  }
`
const Heading = styled.h3`
  text-align: center;
  display: inline;
  font-weight: 600;
  font-size: 25px;
  @media ${breakpoints.desktop} {
    color: ${colors.primary_blue};
    display: block;
    font-size: 16px;
    line-height: 22px;
    width: 140px;
    margin-bottom: 20px;
  }
`
const SeePreferencesLink = styled.a.attrs({ variant: 'link' })`
  display: none;
  @media only screen and ${breakpoints.lg_under} {
    display: block; 
    color: ${colors.primary_blue};
  
    &.a-link {
      text-decoration: underline;
       text-decoration: underline;
  text-decoration-color: ${colors.primary_blue};
    }
    &.a:visited {
      text-decoration: underline;
       text-decoration: underline;
  text-decoration-color: ${colors.primary_blue};
    }
    &.a:hover {
      color: ${colors.text_link_hover}
      text-decoration: underline;
       text-decoration: underline;
  text-decoration-color: ${colors.primary_blue};
    }
    &.a:active {
      text-decoration: underline;
       text-decoration: underline;
  text-decoration-color: ${colors.primary_blue};
    }
    font-size: 20px;
    line-height: 27px;
    color: ${colors.primary_blue}
    font-weight: 600px;
    text-decoration: underline;
  }
  .icon {
    padding-right: 15px;
    padding-bottom: 5px;
    width: 50px;
  }
`

const ViewPrefs = styled.div`
  display: none;

  @media only screen and (${breakpoints.lg_above}) {
    display: block;
  }
`
const ViewTabletPrefs = styled.div`
  display: none;
  @media only screen and ${breakpoints.lg_under} {
    display: flex;
    justify-content: center;
  }
`

const ViewPreferences = () => {
  const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
  const [showing, setShowing] = useState(false)
  const isVerifiedUser = useSelector(selectIsUserVerified)
  const { t } = useTranslation()

  const onClickAction = () => {
    if (isVerifiedUser) {
      setShowing(true)
    } else {
      setShowVerificationPopUp(true)
    }
  }

  return (
    <>
      <ViewTabletPrefs>
        <SeePreferencesLink onClick={() => onClickAction()}>
          <img
            src={GearIcon}
            alt={`${t('deliveryPreferencesSummary.see_preferences')} icon`}
            className="icon"
          />
          <Heading>{t('deliveryPreferencesSummary.see_preferences')}</Heading>
        </SeePreferencesLink>
        <PreferencesSummary show={showing} onHide={() => setShowing(false)} />
        <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
      </ViewTabletPrefs>

      <ViewPrefs>
        <Heading>{t('deliveryPreferencesSummary.see_preferences')}</Heading>
      </ViewPrefs>
      <PreferencesContainer>
        <ViewPrefs>
          <OutlineButton
            icon={GearIcon}
            label={t('myPreferences.my_preferences')}
            action={() => onClickAction()}
          />
          <PreferencesSummary show={showing} onHide={() => setShowing(false)} />
          <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
        </ViewPrefs>
      </PreferencesContainer>
    </>
  )
}

export default ViewPreferences
