import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectActivePinDetails } from 'store/shipping/shippingSelectors'
import { StatusProgressBar, StatusProgressBarItem } from './Status.style'

const StatusBar = () => {
  const { t } = useTranslation()
  const trackingDetails = useSelector(selectActivePinDetails)
  const { statuscode: shipmentStatusCode, missedDeliveryFlag } = trackingDetails

  const targetCode = shipmentStatusCode === 4 && missedDeliveryFlag ? 5 : shipmentStatusCode
  // const numOfPackage = packageDetails.length
  // const statusCodeMapping = {
  //   1: 'picked_on_it_way',
  //   2: 'out_for_delivery',
  //   3: 'delivered',
  //   4: 'delayed',
  //   5: 'missed_delivery',
  // }

  const getStatus = (statusCode) => {
    if (statusCode === 1) {
      return 'status_progress_bar.picked_up'
    }
    if (statusCode === 2) {
      return 'status_progress_bar.delivered'
    }
    if (statusCode === 3) {
      return 'status_progress_bar.delivered'
    }
    if (statusCode === 4) {
      return 'status_progress_bar.delayed'
    }
    return 'status_progress_bar.missed_delivery'
  }

  const getStep2Label = (statusCode) => {
    if (statusCode === 4) {
      return 'status_progress_bar.delayed'
    }
    if (statusCode === 5) {
      return 'status_progress_bar.missed_delivery'
    }
    return 'status_progress_bar.out_for_delivery'
  }

  const getStatusClasses = (statusCode) => {
    const classes = []
    // determine process status class
    if (shipmentStatusCode === 4 && statusCode < 3) {
      classes.push('processed')
    } else if (shipmentStatusCode < 4 && statusCode < 4 && statusCode <= shipmentStatusCode) {
      classes.push('processed')
    }
    // determine current status class
    if (statusCode === shipmentStatusCode) {
      classes.push('current')
    }
    // determine issue status class
    if (shipmentStatusCode === 4 && statusCode < 3) {
      classes.push('issue')
    }
    if (shipmentStatusCode === 4 && statusCode === 2) {
      classes.push('current')
    }
    if (shipmentStatusCode === 3) {
      classes.push('done')
    }
    return classes.join(' ')
  }

  return (
    <StatusProgressBar aria-label={`${t('aria_labels.status')} ${t(getStatus(targetCode))}`}>
      <StatusProgressBarItem className={getStatusClasses(1)}>
        {t('status_progress_bar.picked_up')}
      </StatusProgressBarItem>
      <StatusProgressBarItem className={getStatusClasses(2)}>
        {t(getStep2Label(targetCode))}
      </StatusProgressBarItem>
      <StatusProgressBarItem className={getStatusClasses(3)}>
        {t('status_progress_bar.delivered')}
      </StatusProgressBarItem>
    </StatusProgressBar>
  )
}
export default StatusBar
