import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Checkbox from 'reusableComponents/Checkbox'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { breakpoints } from 'globalStyles'
import TermsAndConditions from 'components/TermsAndConditions'
import { SIGNATURE_PREFERENCE } from 'constants.json'

const SigReleaseAuth = styled.div`
  .signAuthHeader {
    text-align: center;
    margin: 18px 16px;
  }

  .signAuthContent {
    margin: 18px 27px 25px 28px;
    font-size: 13px;
    line-height: 19.5px;
    text-align: left;
  }

  @media ${breakpoints.desktop}, ${breakpoints.tablet} {
    .signAuthHeader {
      font-size: 20px;
      text-align: center;
    }

    .signAuthContent {
      margin: 16px 26px 24px 25px;
      font-size: 14px;
      line-height: 19.5px;
      text-align: left;
    }
  }
`

const SignatureReleaseAuthorization = ({ handleClick }) => {
  const [checkboxClicked, setcheckboxClicked] = useState(false)
  const [validated, setValidated] = useState(false)
  const onCheckBoxClick = (event) => {
    setcheckboxClicked(event.target.checked)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'signaturePreferences' })

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (checkboxClicked) {
      handleClick(SIGNATURE_PREFERENCE.RELEASE_WITHOUT_SIGNATURE)
    }
  }

  return (
    <SigReleaseAuth>
      <Form id="signForm" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="sigAuth">
          <span className="signAuthHeader">{t('signAuthHeader')}</span>
          <div className="signAuthContent">
            <Checkbox
              name="sigAuth"
              value={checkboxClicked}
              onChange={onCheckBoxClick}
              label={
                <Trans t={t} i18nKey="signAuthContent" components={[<TermsAndConditions />]} />
              }
              errText={t('signAuthError')}
              isRequired
            />
          </div>
        </Form.Group>
      </Form>
    </SigReleaseAuth>
  )
}

SignatureReleaseAuthorization.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default SignatureReleaseAuthorization
