import _ from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { breakpointFns } from 'globalStyles'

function ShowForDevice({ children, showOnlyFor, hideOnlyFor }) {
  const checkVisibility = useCallback(() => {
    return (
      _.some(showOnlyFor, (size) => breakpointFns[size]()) ||
      !_.some(hideOnlyFor, (size) => breakpointFns[size]())
    )
  }, [showOnlyFor, hideOnlyFor])

  const [isVisible, setIsVisible] = useState(checkVisibility())

  const updateIsVisible = useCallback(() => {
    setIsVisible(checkVisibility())
  }, [checkVisibility])

  useEffect(() => {
    window.addEventListener('resize', updateIsVisible)

    return function cleanup() {
      window.removeEventListener('resize', updateIsVisible)
    }
  }, [updateIsVisible])

  return isVisible && children
}

ShowForDevice.propTypes = {
  showOnlyFor: PropTypes.arrayOf(PropTypes.string),
  hideOnlyFor: PropTypes.arrayOf(PropTypes.string),
}

ShowForDevice.defaultProps = {
  showOnlyFor: [],
  hideOnlyFor: ['phone', 'tablet', 'desktop'],
}

export default ShowForDevice
