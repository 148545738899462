import React from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { breakpoints } from 'globalStyles'
import styled from 'styled-components'

const StyledCheckbox = styled(Form.Check)`
  .invalid-feedback {
    font-size: 12px;
    margin-top: 12px;
    line-height: 16.34px;
  }

  @media ${breakpoints.desktop}, ${breakpoints.tablet} {
    .invalid-feedback {
      font-size: 14px;
      line-height: 19.07px;
    }
  }
`

const Checkbox = ({ label, errText, isRequired, name, value, onChange }) => {
  return (
    <StyledCheckbox
      label={label}
      checked={value}
      onChange={onChange}
      name={name}
      required={isRequired}
      feedback={errText}
      feedbackType="invalid"
    />
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  errText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  errText: null,
  isRequired: false,
}

export default Checkbox
