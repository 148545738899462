import { React, useRef, useState } from 'react'
import Chevron from '@mui/icons-material/KeyboardArrowDown'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container, Items, Menu } from './MenuItem.style'

const MenuItem = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  // Set the maxHeight of the accordion content to the height of the content
  const accordionContent = useRef()

  const onClickMenu = () => {
    // Closing the opened menu items.
    const openedItem = document.getElementById('menu-item-active-true')
    if (openedItem) {
      openedItem.click()
    }
    setIsOpen(!isOpen)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return (
    <Container aria-label={t('menu_container')}>
      <Menu id={`menu-item-active-${isOpen}`} onClick={onClickMenu} active={isOpen}>
        <Chevron />
        <span>{header}</span>
      </Menu>
      <Items
        ref={accordionContent}
        height={accordionContent?.current?.scrollHeight}
        isOpen={isOpen}
      >
        {children}
      </Items>
    </Container>
  )
}

MenuItem.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default MenuItem
