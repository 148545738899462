import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { selectSubscribedFlag } from 'store/shipping/shippingSelectors'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { colors } from 'globalStyles'

const StyledCovidBanner = styled.a`
  background-color: #f7dd60;
  text-align: center;
  padding: 5px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  text-decoration: underline;
  color: ${colors.primary_black};
  svg {
    margin-right: 4px;
    width: 20px;
    fill: ${colors.primary_black};
  }
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-color: ${colors.text_link_hover};
    color: ${colors.text_link_hover};
  }

  &:focus {
    text-decoration-thickness: 1.5px;
    text-decoration-color: ${colors.text_link_hover};
    color: ${colors.text_link_hover};
  }
`
const CovidBanner = () => {
  const { t } = useTranslation()
  const subscribedFlag = useSelector(selectSubscribedFlag)
  const covidLink = t(process.env.REACT_APP_SUBSCRIBE_LINK)
  const handleOnClick = useCallback(() => {
    console.log(t(process.env.REACT_APP_SUBSCRIBE_LINK))
    window.open(covidLink, '_blank')
  }, [covidLink])

  return (
    !subscribedFlag && (
      <StyledCovidBanner
        role="button"
        onClick={() => {
          handleOnClick()
        }}
      >
        <ReportProblemOutlinedIcon />
        {t('covid_banner_text')}
      </StyledCovidBanner>
    )
  )
}

export default CovidBanner
