import { get } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import {
  getShippingInfo,
  updateSession,
  deleteSession,
  setDeliveryPreferences,
  getPhotoPODImages,
} from 'api/shipping'
import {
  getSessionValidity,
  removeCookieFromLocalStorage,
  setCookieToLocalStorage,
  getCookieIDFromLocalStorage,
} from 'utils/helperFunctions'
import { selectTrackingDetails } from './shippingSelectors'

const shipmentPinPathParam = window.location.pathname.split('/')[4]
const initialState = {
  info: null,
  loading_info: false,
  error_info: null,
  selectedPackagePinInfo: null,
  isVerifiedUser: getSessionValidity(shipmentPinPathParam),
  allowRenewSession: false,
}

export const shippingSlice = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    setShippingInfo: (state, action) => {
      state.info = action.payload
    },
    setSelectedPackagePinInfo: (state, action) => {
      state.selectedPackagePinInfo = action.payload
    },
    setShippingPreferences: (state, action) => {
      state.info.buzzer_code = action.payload.buzzer_code
      state.info.additional_delivery_instructions = action.payload.additional_delivery_instructions
      state.info.delivery_preference = action.payload.delivery_preference
      state.info.preference_id = action.payload.preference_id
      state.info.preference_modified = action.payload.preference_modified
      state.info.signature = action.payload.signature
      state.info.remote_signature_preference = action.payload.remote_signature_preference
    },
    loadingShippingInfo: (state, action) => {
      state.loading_info = action.payload
    },
    errorLoadingShippingInfo: (state, action) => {
      state.error_info = action.payload
    },
    setSelectedPin: (state, action) => {
      state.selectedPin = action.payload
    },
    setIsVerifiedUser: (state, action) => {
      state.isVerifiedUser = action.payload
    },
    setAllowRenewSession: (state, action) => {
      state.allowRenewSession = action.payload
    },
    setPhotoPOD: (state, action) => {
      state.info.photoPODImages = action.payload
    },
  },
})

const {
  setShippingInfo,
  loadingShippingInfo,
  errorLoadingShippingInfo,
  setSelectedPackagePinInfo,
  setShippingPreferences,
  setSelectedPin,
  setPhotoPOD,
} = shippingSlice.actions

export const { setIsVerifiedUser, setAllowRenewSession } = shippingSlice.actions

export const getShippingInformation = (shipmentPin, lang) => async (dispatch) => {
  dispatch(loadingShippingInfo(true))
  let shippingInfo = null
  try {
    shippingInfo = await getShippingInfo(shipmentPin, lang)
    const { data } = shippingInfo
    const selectedPackagePinInfo = data.trackingHistoryData[data.shipmentPin]
    dispatch(setShippingInfo(data))
    dispatch(setSelectedPackagePinInfo(selectedPackagePinInfo))
    dispatch(setSelectedPin(data.shipmentPin))
  } catch (e) {
    console.log('err', e) // eslint-disable-line no-console
    if (get(e, 'response.data', null)) {
      dispatch(errorLoadingShippingInfo(e.response.data))
    }
  } finally {
    dispatch(loadingShippingInfo(false))
  }
}

export const updateTrackingDetails = (pin) => (dispatch, state) => {
  const trackingDetails = selectTrackingDetails(state())[pin]
  dispatch(setSelectedPackagePinInfo(trackingDetails))
  dispatch(setSelectedPin(pin))
}

export const updatePhotoPODImagesByPin = (pin) => async (dispatch) => {
  const photoPODImages = await getPhotoPODImages(pin)
  dispatch(setPhotoPOD(photoPODImages.data))
}

export const endSession = () => async (dispatch, getState) => {
  const { id, hashedPin } = getState()?.shipping?.info || {}
  removeCookieFromLocalStorage(hashedPin)
  const result = await deleteSession(id)
  if (result.data.response === 'SESSION_DELETED') {
    dispatch(setIsVerifiedUser(false))
  }
}
export const renewSession = () => async (dispatch, getState) => {
  const { id, hashedPin } = getState()?.shipping?.info || {}
  console.log('shippingSlice shipmentPin', hashedPin, getState()?.shipping?.info)
  const result = await updateSession(id, hashedPin)
  if (result.data.response === 'SESSION_UPDATED') {
    const { expiryTime, updateExpTime } = result.data
    setCookieToLocalStorage(hashedPin, getCookieIDFromLocalStorage(hashedPin), expiryTime)
    dispatch(setAllowRenewSession(updateExpTime))
  }
}

export const setShipmentPreferences =
  (additionalDeliveryInstructions, buzzerCode, dropOffLocation, signaturePreference) =>
  async (dispatch, getState) => {
    const { id, hashedPin } = getState().shipping.info
    const result = await setDeliveryPreferences(
      'SHIPMENT',
      'en',
      id,
      additionalDeliveryInstructions,
      buzzerCode,
      dropOffLocation,
      signaturePreference,
      hashedPin
    )
    dispatch(setShippingPreferences(result.data))
  }

export default shippingSlice.reducer
