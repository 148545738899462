import React from 'react'
import PropTypes from 'prop-types'
import { breakpoints, colors } from 'globalStyles'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const DropDownStyled = styled(Dropdown)`
  display: flex;
  .dropdown-toggle-split {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .dropdown-toggle:hover {
    border-bottom: 2px solid ${colors.primary_blue} !important;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-toggle[aria-expanded='true'] .fa-chevron-down {
    transform: rotate(180deg);
  }
  .dropdown-menu {
    max-height: 178px;
    z-index: 10;
    min-width: unset;
    overflow-y: auto;
    padding: 0 0 !important;
    position: absolute;
    /* style changes for dropdown for show only down*/
    inset: 0 auto auto 0 !important;
    transform: translate3d(-8px, 42px, 0) !important;
    @media ${breakpoints.tablet_above} {
      min-width: 180px;
    }
  }
  dropdown-menu.show {
    display: block !important;
  }
  .dropdown-item {
    padding: 2px 15px !important;
    font-size: 14px;

    @media ${breakpoints.desktop} {
      font-size: 16px;
    }
  }
  .dropdown-item:active {
    background-color: ${colors.secondary_blue} !important;
    color: ${colors.primary_white} !important;
  }
  .dropdown-item:focus {
    outline: 1px solid ${colors.primary_dark_grey};
  }
  .btn-check:checked + .btn-primary:focus,
  .btn-check:active + .btn-primary:focus,
  .btn-primary:active:focus,
  .btn-primary.active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    box-shadow: none !important;
  }
`
const SpanStyled = styled.span`
  background-color: ${colors.primary_white};
  border: 0;
  height: 100%;
  font-size: 14px;
  margin-right: 2px;
  @media ${breakpoints.desktop} {
    font-size: 16px;
  }
`

const DropDown = ({ items, isDisabled, onSelectHandler, value, id }) => {
  const ddToggleStyle = {
    color: colors.secondary_blue,
    backgroundColor: colors.primary_white,
    border: `2px solid ${colors.primary_white}`,
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: 'unset',
  }

  const ddMenuStyle = {
    position: 'absolute',
    inset: '0 auto auto 0',
    padding: '0 0',
  }

  const onClickHandler = (event) => {
    const selected = event.target.innerHTML
    onSelectHandler(selected)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return (
    <DropDownStyled placement="bottom-end">
      <SpanStyled>{value}</SpanStyled>
      <Dropdown.Toggle
        style={ddToggleStyle}
        split
        id={id}
        data-toggle="dropdown"
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={faChevronDown} size="2xs" color={colors.primary_blue} />
      </Dropdown.Toggle>
      <Dropdown.Menu style={ddMenuStyle}>
        {items.map((item, i) => (
          <Dropdown.Item
            aria-label={t('shipment_pin')}
            active={item === value}
            key={i}
            onClick={onClickHandler}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropDownStyled>
  )
}

DropDown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
}

DropDown.defaultProps = {
  isDisabled: false,
  value: '',
}

export default DropDown
