import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NotFound from 'pages/NotFound'
import PurolatorYourWay from 'components/PurolatorYourWay'
import SmsToggle from 'components/SmsToggle'
import TrackingNumber from 'components/TrackingNumber'
import DeliveryDate from 'components/DeliveryDate'
import StatusBar from 'components/StatusBar'
import AddressValidation from 'components/AddressValidation'
import ShipmentDetails from 'components/ShipmentDetails'
import TransitHistory from 'components/TransitHistory'
import {
  getShippingInformation,
  updateTrackingDetails,
  updatePhotoPODImagesByPin,
} from 'store/shipping/shippingSlice'
import {
  selectIsLoading,
  selectPackageStatus,
  selectShippingInfo,
  selectIsUserVerified,
  selectIsLeadPinDelivered,
} from 'store/shipping/shippingSelectors'
import ImageBanner from 'components/ImageBanner'

import LoadingTruck from 'reusableComponents/LoadingTruck'
import SetPreferences from 'components/Preferences/SetPreferences'
import ViewPreferences from 'components/ViewPreferences'
import PhotoPOD from 'components/PhotoPod'
import ShowForDevice from 'reusableComponents/ShowForDevice'
import SessionTimeOutModal from 'components/SessionTimeOutModal'

import {
  Container,
  ShippingContainer,
  ShipmentDetailsContainer,
  ShippingInfoContainer,
  StickyContainer,
  Sticky,
} from './shipping.styles'

const ShippingInfo = () => {
  const dispatch = useDispatch()
  const { pin } = useParams()
  const { i18n } = useTranslation()
  const { language: lang } = i18n

  const isUserVerified = useSelector(selectIsUserVerified)

  useEffect(() => {
    dispatch(getShippingInformation(pin, lang))
  }, [dispatch, pin, lang, isUserVerified])

  const isLoading = useSelector(selectIsLoading)
  const shippingInfo = useSelector(selectShippingInfo)
  const packageStatus = useSelector(selectPackageStatus)
  const isLeadPinDelivered = useSelector(selectIsLeadPinDelivered)

  if (isLoading) {
    return <LoadingTruck />
  }

  if (!shippingInfo) {
    return <NotFound />
  }
  const {
    vendor,
    photoPODImages = [],
    featureFlags: { addressVerification: isAddressVerificationFeatureEnabled },
    optedinforsms,
  } = shippingInfo

  const hasPhotoPOD = photoPODImages.length > 0
  const onSelectPinHandler = (selectedPin) => {
    dispatch(updateTrackingDetails(selectedPin))
    dispatch(updatePhotoPODImagesByPin(selectedPin))
  }
  return (
    <Container>
      <ImageBanner shipmentStatus={packageStatus} vendor={vendor.name} />
      <PurolatorYourWay />
      <ShipmentDetailsContainer>
        <ShippingContainer>
          <SmsToggle optedIn={optedinforsms} />
          <TrackingNumber onSelectPinHandler={onSelectPinHandler} />
          <StatusBar />
          <ShippingInfoContainer>
            <ShowForDevice showOnlyFor={['phone', 'tablet']}>
              <DeliveryDate />
              {isLeadPinDelivered ? <ViewPreferences /> : <SetPreferences />}
              {isAddressVerificationFeatureEnabled && <AddressValidation />}
              {hasPhotoPOD && <PhotoPOD photoPODImages={photoPODImages} />}
              <TransitHistory />
              <ShipmentDetails onSelectPinHandler={onSelectPinHandler} />
            </ShowForDevice>
            <ShowForDevice showOnlyFor={['desktop']}>
              {hasPhotoPOD ? (
                <>
                  <div style={{ display: 'grid', gap: 33 }}>
                    <DeliveryDate />
                    {isAddressVerificationFeatureEnabled && <AddressValidation />}
                  </div>
                  <PhotoPOD photoPODImages={photoPODImages} />
                </>
              ) : (
                <>
                  <DeliveryDate />
                  {isAddressVerificationFeatureEnabled && <AddressValidation />}
                </>
              )}
              <TransitHistory />
              <ShipmentDetails onSelectPinHandler={onSelectPinHandler} />
            </ShowForDevice>
          </ShippingInfoContainer>
        </ShippingContainer>
        <ShowForDevice showOnlyFor={['desktop']}>
          <StickyContainer>
            <Sticky>{isLeadPinDelivered ? <ViewPreferences /> : <SetPreferences />}</Sticky>
          </StickyContainer>
        </ShowForDevice>
      </ShipmentDetailsContainer>
      <SessionTimeOutModal sessionActive={isUserVerified} />
    </Container>
  )
}

export default ShippingInfo
