import { useEffect } from 'react'

export const useScrollToSection = (refToSection, hashRouteValue, location) => {
  const { pathname, hash, key } = location
  useEffect(() => {
    if (hash && refToSection) {
      const component = hash.replace('#', '')
      if (component === hashRouteValue) {
        if (refToSection !== 'TOP') {
          refToSection.current.scrollIntoView()
        } else {
          window.scrollTo(0, 0)
        }
      }
    }
  }, [pathname, hash, key, hashRouteValue, refToSection])
}
