import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip as BootStrapTooltip } from 'react-bootstrap'
import Overlay from 'react-bootstrap/Overlay'
import toolTipIcon from 'assets/svg/tooltip.svg'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'

const StyledTooltip = styled(BootStrapTooltip)`
  width: 143px;
  line-height: 19px;
  @media ${breakpoints.desktop} {
    width: 285px;
  }
`

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  height: 18.2px;
  width: 18.2px;
  &:focus,
  &:hover {
    cursor: pointer;
    border-radius: 14px;
    border: 1px solid ${colors.primary_blue};
  }
`

const TooltipIcon = styled.img.attrs({
  alt: 'Tooltip',
  role: 'presentation',
})`
  width: 82%;
  height: 82%;
`

const ToolTip = ({ placement, children, id }) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  return (
    <>
      <TooltipWrapper>
        <TooltipIcon src={toolTipIcon} ref={target} onClick={() => setShow(!show)} />
      </TooltipWrapper>
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose="true"
        rootCloseEvent="click"
        onHide={() => setShow(false)}
      >
        <StyledTooltip id={id}>{children}</StyledTooltip>
      </Overlay>
    </>
  )
}

ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

ToolTip.defaultProps = {
  children: '',
}

export default ToolTip
