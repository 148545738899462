import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from 'globalStyles'
import { Trans, useTranslation } from 'react-i18next'
import RadioButton from 'reusableComponents/RadioButton'
import TermsAndConditions from 'components/TermsAndConditions'
import { businessLocations, locations } from './config'

const HeaderStyledSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  float: left;
  padding: 5px 0 7px 0;

  @media screen and ${breakpoints.lg_above} {
    font-size: 18px;
  }
`
const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
  margin-left: 5px;
  text-align: left;
  /*Radio Button Form Styles*/
  .form-check {
    min-height: 28px;
    height: auto;
    width: 100%;
    float: left;
  }
  .form-check-label {
    font-size: 14px !important;
  }

  .tncAgr {
    margin-top: 18px;
    font-size: 12px;
    float: left;

    @media screen and ${breakpoints.sm_above} {
      font-size: 14px;
    }
  }
`

const DropOffLocation = ({ onValueChangeHandler, selectedItem }) => {
  const { t } = useTranslation(['translation', 'termsAndConditions'])
  const [selectedLocation, setSelectedLocation] = useState(selectedItem)

  const onLocationChangeHandler = (event) => {
    onValueChangeHandler(event)
    setSelectedLocation(event.target.value)
  }

  return (
    <Container>
      {locations.map((item) => (
        <RadioButton
          key={item.value}
          id={item.value}
          name="locations"
          value={item.value}
          label={t(item.label)}
          onChange={onLocationChangeHandler}
          selectedValue={selectedLocation}
          enabled={item.enabled}
        />
      ))}
      <HeaderStyledSpan>{t('deliveryPreferencesSummary.business_locations')}</HeaderStyledSpan>
      {businessLocations.map((item) => (
        <RadioButton
          key={item.value}
          id={item.value}
          name="businessLocations"
          value={item.value}
          label={t(item.label)}
          onChange={onLocationChangeHandler}
          selectedValue={selectedLocation}
          enabled={item.enabled}
        />
      ))}
      <p className="tncAgr">
        <Trans
          t={t}
          i18nKey="deliveryPreferencesSummary.terms_and_conditions_text"
          components={[<TermsAndConditions />]}
        />
      </p>
    </Container>
  )
}

DropOffLocation.propTypes = {
  selectedItem: PropTypes.string,
  onValueChangeHandler: PropTypes.func,
}

DropOffLocation.defaultProps = {
  selectedItem: '',
  onValueChangeHandler: null,
}

export default DropOffLocation
