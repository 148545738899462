/* eslint-disable */
class FAQsAccordion extends HTMLElement {
  constructor() {
    super()
    this.root = this.attachShadow({ mode: 'closed' })
    this.root.appendChild(FAQsAccordion.__style.content.cloneNode(true))
    this.root.appendChild(FAQsAccordion.__template.content.cloneNode(true))
    this.container = this.root.querySelector('.accordion')

    // Id system and map to track items inside accordion
    this.itemId = 0
    this.nodes = new Map()
  }

  
  // clickItem(id) is triggered when an accordion item is clicked
  // It can modify other accordion items
  clickItem(id) {
    this.nodes.forEach((value) => {
      // skip the item which is being clicked
      if (value.itemId === id) {
        return
      }
      // close all open items
      if (value.isOpen) {
        let valueBody = value.querySelector('.accordion-body')
        value.classList.remove('open')
        valueBody.style.maxHeight = null

        value.isOpen = false
      }
    })
    let self = this.nodes.get(id)
    let selfBody = self.querySelector('.accordion-body')
    let selfTitle = self.querySelector('.accordion-title')
    // toggle open on the item
    self.isOpen = !self.isOpen
    self.isOpen ? self.classList.add('open') : self.classList.remove('open')
    self.isOpen
      ? (selfBody.style.maxHeight = `${selfBody.scrollHeight}px`)
      : (selfBody.style.maxHeight = null)
    selfTitle.setAttribute("aria-expanded", self.isOpen)
  }

  // addItem(title, body) add an accordion item to the accordion, returns the id of added item
  addItem(title, body) {
    const item = FAQsAccordion.__item_template.content
      .cloneNode(true)
      .querySelector('.accordion-item')
    const selfID = this.itemId
    this.nodes.set(selfID, item)
    this.container.appendChild(item)
    item.isOpen = false
    item.itemId = selfID

    const itemTitle = item.querySelector('.accordion-title')
    const itemBody = item.querySelector('.accordion-body')
    itemTitle.setAttribute("id", `Question ${item.itemId}`)
    itemTitle.setAttribute("aria-expanded", item.isOpen)
    itemTitle.setAttribute("aria-controls", `Answer ${item.itemId}`)
    itemBody.setAttribute("id", `Answer ${item.itemId}`)
    itemBody.setAttribute("aria-labelledby", `Question ${item.itemId}`)

    item.querySelector('.title-text').innerHTML = title
    item.querySelector('.accordion-body-content').innerHTML = body
    itemTitle.addEventListener('click', () => {
      this.clickItem(selfID)
    })
    /* eslint-disable-next-line no-plusplus */
    this.itemId++
    return selfID
  }

  // getItem(id) takes an item's id and returns the element itself
  getItem(id) {
    return this.nodes.get(id)
  }

  // removeItem(id) takes an item's id and removes it from the accordion (if doesn't exists, this does nothing)
  removeItem(id) {
    const removedNode = this.nodes.get(id)
    if (removedNode) {
      this.container.removeChild(removedNode)
      this.nodes.delete(id)
    }
  }

  // empty() removes all items currently in the accordion
  empty() {
    this.nodes.forEach((node, key) => {
      this.removeItem(key)
    })
  }
  connectedCallback() {}
  disconnectedCallback() {}
}
Object.defineProperties(FAQsAccordion, {
  __style: {
    configurable: false,
    enumerable: false,
    writable: false,
    value: document.createElement('template'),
  },
  __template: {
    configurable: false,
    enumerable: false,
    writable: false,
    value: document.createElement('template'),
  },
  __item_template: {
    configurable: false,
    enumerable: false,
    writable: false,
    value: document.createElement('template'),
  },
})
FAQsAccordion.__style.innerHTML = `
    <style>
      .accordion {
        display: flex;
        flex-direction: column;
      }
      .accordion-item {
        width: 100%;
        border-bottom: 1px solid #D3D3D3;
        height: fit-content;
      }
      .accordion-item:first-child {
        border-top: 1px solid #D3D3D3;
      }
      .accordion-item.open > .accordion-body {
        visibility: visible;
      }
      .accordion-item > .accordion-title > .title-arrow {
        transition: transform 250ms;
      }
      .accordion-item.open > .accordion-title > .title-arrow {
        transform: rotate(90deg);
      }
      .accordion-title {
        margin: unset;
        width: 100%;
        border: none;
        min-height: 40px;
        cursor: pointer;
        display: flex;
        padding: 9px 18px;
        align-items: stretch;
        background-color: unset;
      }
      .title-text {
        flex-grow: 1;
        color: #001996;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-align: left;
      }
      .title-arrow {
        flex-shrink: 0;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title-arrow > svg {
        width: 7.5px;
        height: 12.5px;
      }
      .accordion-body {
        max-height: 0;
        transition: max-height 250ms ease 0ms, visibility 250ms ease 0ms;
        overflow: hidden;
        visibility: hidden;
      }
      .accordion-body-content {
        padding: 0 18px 15px 18px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-style: normal;
      }
    </style>
  `
FAQsAccordion.__template.innerHTML = `
    <div class="accordion"></div>
  `


FAQsAccordion.__item_template.innerHTML = `
    <div class="accordion-item">
      <button class="accordion-title">
        <div class="title-text"></div>
        <div class="title-arrow">
          <svg viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.441375 12.6866C0.391314 12.6422 0.351596 12.5894 0.324496 12.5313C0.297396 12.4732 0.283447 12.4109 0.283447 12.348C0.283447 12.2851 0.297396 12.2228 0.324496 12.1647C0.351596 12.1066 0.391314 12.0539 0.441375 12.0094L6.51258 6.6089L0.441375 1.20838C0.391395 1.16391 0.351748 1.11112 0.324699 1.05303C0.29765 0.994927 0.283728 0.932657 0.283728 0.869772C0.283728 0.806887 0.29765 0.744617 0.324699 0.686518C0.351748 0.628419 0.391395 0.57563 0.441375 0.531163C0.491355 0.486696 0.55069 0.451424 0.615992 0.427358C0.681294 0.403293 0.751285 0.390907 0.821967 0.390907C0.89265 0.390907 0.962641 0.403293 1.02794 0.427358C1.09324 0.451424 1.15258 0.486696 1.20256 0.531163L7.65328 6.27029C7.70334 6.31472 7.74306 6.3675 7.77016 6.4256C7.79726 6.4837 7.81121 6.54599 7.81121 6.6089C7.81121 6.67181 7.79726 6.7341 7.77016 6.7922C7.74306 6.85031 7.70334 6.90308 7.65328 6.94751L1.20256 12.6866C1.15263 12.7312 1.09331 12.7665 1.028 12.7906C0.962689 12.8147 0.892676 12.8271 0.821968 12.8271C0.75126 12.8271 0.681247 12.8147 0.615939 12.7906C0.55063 12.7665 0.49131 12.7312 0.441375 12.6866Z" fill="black"/>
          </svg>
        </div>
      </button>
      <div class="accordion-body">
        <div class="accordion-body-content"></div>
      </div>
    </div>
  `
customElements.define('purolator-faqs-accordion', FAQsAccordion)

class FAQs extends HTMLElement {
  static get observedAttributes() {
    return ['content', 'lang']
  }
  attributeChangedCallback(name, oldValue, newValue) {
    // Skip initial attricbuteChangedCallback
    if (oldValue === null) {
      return
    }

    switch (name) {
      case 'content':
        this.changeData(newValue, this.getAttribute('lang'), this.accordion)
        break
      case 'lang':
        this.changeLanguage(this.data, newValue, this.accordion)
        break
      default:
        break
    }
  }
  changeLanguage(data, language, accordion) {
    accordion.empty()
    data[language].map((item) => accordion.addItem(item.Q, item.A))
  }
  changeData(url, language, accordion) {
    accordion.empty()

    const loadingItem = accordion.addItem('Loading FAQs...', 'Content loading...')
    const data = JSON.parse(url)
    if (data) {
      this.data = data
      data[language].map((item) => {
        
        let finalContent = ''
        item.A.forEach((element) => {
          finalContent += element.concat('<br />')
        })
        return accordion.addItem(item.Q, finalContent)
      })
      accordion.removeItem(loadingItem)
    } else {
      accordion.addItem('Error Loading FAQs', `Content not found or error parsing.`)
      accordion.removeItem(loadingItem)
    }
  }

  constructor() {
    super()
    this.root = this.attachShadow({ mode: 'closed' })
  }

  connectedCallback() {
    let contentURL = this.getAttribute('content')
    let language = this.getAttribute('lang')

    let accordion = new FAQsAccordion()
    this.accordion = accordion
    this.root.append(accordion)
    this.changeData(contentURL, language, accordion)
  }

  disconnectedCallback() {}
}
customElements.define('purolator-faqs', FAQs)
