import React from 'react'
import { Form, FormCheck } from 'react-bootstrap'
import PropTypes from 'prop-types'

const RadioButton = ({ value, name, id, selectedValue, inline, onChange, label, enabled }) => {
  return (
    <Form>
      <FormCheck key={value} inline={inline}>
        <Form.Check.Input
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={selectedValue === value}
          onChange={onChange}
          disabled={!enabled}
        />
        <Form.Check.Label htmlFor={value}>{label}</Form.Check.Label>
      </FormCheck>
    </Form>
  )
}
RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
}
RadioButton.defaultProps = {
  selectedValue: null,
  inline: false,
  enabled: true,
}
export default RadioButton
