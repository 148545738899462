import { useState } from 'react'

/**
 * @param {String} key The key to store our data to
 * @param {String} initValue The initValue to set to the Cookie
 */
const getCookie = (key, initValue) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(key))
    ?.split('=')[1]

  if (cookieValue) {
    return decodeURIComponent(cookieValue)
  }
  return initValue
}

/**
 * @param {String} key The key to store our data to
 * @param {String} value The value to set to the Cookie
 * @param {Number} numberOfMinutes The Cookie validity time
 */
const setItem = (key, value, numberOfMinutes) => {
  const now = new Date()
  /* set the time to be now + numberOfDays */
  now.setTime(now.getTime() + numberOfMinutes * 60 * 1000)
  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`
}

/**
 * @param {String} key The key to store our data to
 * @param {String} initValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key, initValue) => {
  /* Search the required cookie in the Existing list. If not set default value. */
  const [cookie, setCookie] = useState(getCookie(key, initValue))

  /* Update the cookie value and the Expiration time. */
  const updateCookie = (value, numberOfMinutes) => {
    setCookie(value)
    setItem(key, value, numberOfMinutes)
  }

  /* Remove the Cookie from the list */
  const removeCookie = () => {
    setCookie(null)
    setItem(key, null, 0)
  }

  return [cookie, updateCookie, removeCookie]
}

export default useCookie
