import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PkgNotFoundSVG } from 'assets/svg/Pkg_NotFound.svg'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  backgroundcolor: #f5f5f5;
  .txt-margin {
    margin: 30px;
  }
`
const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <PkgNotFoundSVG />
      <p className="txt-margin">
        {t('pageNotFound.packageNotFoundText')}
        <br />
        {t('pageNotFound.contactInfoText')}
        {t('pageNotFound.phoneNumber')}
      </p>
    </Wrapper>
  )
}

export default NotFound
