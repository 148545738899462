import styled from 'styled-components'
import { colors } from 'globalStyles'

const HEADER_HEIGHT = '62px'

export const HeaderContainer = styled.div.attrs({ 'aria-label': 'Navigation Bar' })`
  width: 100%;
  height: ${HEADER_HEIGHT};
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 250ms;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
  background-color: white;
  position: relative;
`

export const CenterContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: center;
  height: 100%;
`
export const LanguageSelectorWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 19px;
  margin-right: 2px;
  &:hover {
    border-bottom: 5px solid ${colors.secondary_light_blue};
  }
  &:active {
    border-bottom: 5px solid ${colors.primary_blue};
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex-basis: 150px;
  justify-content: end;
  align-items: center;
  height: 100%;
`
export const HeaderLogoutButton = styled.button`
  border: none;
  background: none;
  background-color: ${colors.primary_white};
  color: ${colors.primary_black};
  font-weight: 600;
`
export const HeaderLogoutButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 19px;
  margin-right: 39px;
  font-weight: 600;
  &:hover {
    border-bottom: 5px solid ${colors.secondary_light_blue};
  }
  &:active {
    border-bottom: 5px solid ${colors.primary_blue};
  }
`
