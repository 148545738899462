import styled from 'styled-components'
import { colors, breakpoints } from 'globalStyles'

const FooterStyle = styled.div`
  width: 100%;
  min-height: 124px;
  background-color: ${colors.primary_blue};
  padding: 16px 24px;
  color: ${colors.web_text_light};
  position: relative;
  display: flow-root;

  @media only screen and ${breakpoints.sm_under} {
    min-height: 175px;
    padding: 27px 20px;
  }
`
const FooterLanguageSection = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;

  @media only screen and ${breakpoints.sm_under} {
    gap: 6px;
  }

  button {
    color: ${colors.web_text_light};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  button:hover {
    text-decoration: underline;
  }
`
const FooterTCSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media only screen and ${breakpoints.sm_under} {
    flex-direction: column;
    gap: 14px;
    a {
      border: none !important;
      padding: 0px !important;
    }
  }
`
const FooterTCLink = styled.a`
  padding: 0px 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: ${colors.web_text_light};

  &:hover {
    color: ${colors.web_text_light};
    text-decoration: underline;
  }
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
  &:not(:last-child) {
    border-right: 1px solid white;
  }
`
const FooterCopyright = styled.div`
  float: right;
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;

  @media only screen and ${breakpoints.sm_under} {
    margin-top: 14px;
  }
`

export { FooterStyle, FooterLanguageSection, FooterTCSection, FooterTCLink, FooterCopyright }
