import { React, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  DropdownHeaderButton,
  DropdownHeaderWrapper,
  DropdownLinkStyle,
  DropdownMenu,
  DropdownWrapper,
} from './MenuDropdown.style'

const DropdownHeader = ({ children, title }) => {
  return (
    <DropdownHeaderButton aria-controls={`dropdown for: ${title}`}>{children}</DropdownHeaderButton>
  )
}
DropdownHeader.propTypes = {
  children: PropTypes.string,
  title: PropTypes.string,
}
DropdownHeader.defaultProps = {
  children: '',
  title: '',
}

const DropdownItem = ({ children, clickHandler }) => (
  <DropdownLinkStyle onClick={clickHandler}>{children}</DropdownLinkStyle>
)

DropdownItem.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

const MenuDropdown = ({ header, children }) => {
  const [show, setShow] = useState(false)
  const [itemSelected, setItemSelected] = useState(false)

  const itemSelectHandler = () => {
    setShow(false)
    setItemSelected(true)
  }
  const onMouseLeaveHandler = () => {
    setShow(true)
    setItemSelected(false)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return (
    <DropdownWrapper
      className={show ? 'show' : ''}
      onMouseLeave={() => setShow(false)}
      onFocus={() => setShow(true)}
      menuItemSelected={itemSelected}
    >
      <DropdownHeaderWrapper onClick={() => setShow(true)}>{header}</DropdownHeaderWrapper>
      <DropdownMenu
        className="dropdownMenu"
        aria-label={t('drop_down_menu')}
        onMouseLeave={onMouseLeaveHandler}
        onClick={itemSelectHandler}
      >
        {children}
      </DropdownMenu>
    </DropdownWrapper>
  )
}

MenuDropdown.Header = DropdownHeader
MenuDropdown.Item = DropdownItem

MenuDropdown.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
}
MenuDropdown.defaultProps = {
  header: null,
  children: null,
}

export default MenuDropdown
