import { get, post } from 'utils/apiHelper'
import { getCookieIDFromLocalStorage, getCookieExpireFromLocalStorage } from 'utils/helperFunctions'

const getAuthHeaders = (hashedPin) => {
  const sessionId = getCookieIDFromLocalStorage(hashedPin)
  const sessionExpire = getCookieExpireFromLocalStorage(hashedPin)
  console.log(sessionId, sessionExpire)
  if (!sessionId) return {}
  return {
    'session-id': sessionId,
    'session-expire': new Date(sessionExpire * 1000).toISOString(),
  }
}

export const getShippingInfo = (hashedPin, lang) =>
  get({
    path: `/progress/${hashedPin}/${lang}`,
    config: { headers: getAuthHeaders(hashedPin) },
  })

export const getDeliveryDateInfo = (shipmentPin) =>
  post({ path: `/deliverydate`, body: { shipmentPin } })

export const getPhotoPODImages = (shipmentPin) =>
  get({
    path: `/getPhoto/${shipmentPin}`,
  })

export const setDeliveryPreferences = (
  type,
  lan,
  id,
  instructions,
  buzzerCode,
  dropOffLocation,
  signature,
  hashedPin
) =>
  post({
    path: `/setDeliverypreference`,
    body: {
      additional_delivery_instructions: instructions,
      buzzer_code: buzzerCode,
      delivery_preference: dropOffLocation,
      lang: lan,
      preference_modified: type,
      remote_signature_preference: signature,
      shipmentId: id,
    },
    config: {
      headers: getAuthHeaders(hashedPin),
    },
  })

export const verifyPostalCode = (shipmentId, postalCode, locale) =>
  post({ path: `/verifyPostalCode/${shipmentId}/${postalCode}/${locale}` })

export const verifyVerificationCode = (shipmentId, verificationCode, locale) =>
  post({ path: `/verifyVerificationCode/${shipmentId}/${verificationCode}/${locale}` })

export const resendVerificationCode = (shipmentId, locale) =>
  post({ path: `/resendSavePreferencesverificationCode/${shipmentId}/${locale}` })

export const updateSession = (shipmentId, hashedPin) =>
  post({
    path: `/updateSession/${shipmentId}`,
    config: {
      headers: getAuthHeaders(hashedPin),
    },
  })

export const deleteSession = (shipmentId, hashedPin) =>
  post({
    path: `/deleteSession/${shipmentId}`,
    config: {
      headers: getAuthHeaders(hashedPin),
    },
  })

export const updateOptInSMSFlag = (shipmentId, optInFlagSMS) =>
  post({ path: `/updateSMSFlag/${shipmentId}/${optInFlagSMS}` })

export const isSubscribed = (phonenumber) => get({ path: `/isSubscribed/${phonenumber}` })
