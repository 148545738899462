import { useEffect, useState } from 'react'
import { breakpointValue, breakpoints } from 'globalStyles'

const useDetectPhone = () => {
  const [mQuery, setMQuery] = useState({ matches: window.innerWidth < breakpointValue.sm })

  useEffect(() => {
    const mediaQueryList = window.matchMedia(breakpoints.phone)
    mediaQueryList.addEventListener('change', setMQuery)
    return () => mediaQueryList.removeEventListener('change', setMQuery)
  }, [])

  return mQuery.matches
}

export default useDetectPhone
