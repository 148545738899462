import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints } from 'globalStyles'

const Branding = styled.h1`
  color: ${colors.primary_blue};
  font-size: 26px;
  line-height: 25px;
  font-weight: 400;
  @media only screen and ${breakpoints.tablet} {
    font-size: 32px;
    line-height: 44px;
  }
  @media only screen and ${breakpoints.desktop} {
    font-size: 40px;
    line-height: 54px;
  }
`

export default () => {
  const { t } = useTranslation()

  return <Branding>{t('pyw')}</Branding>
}
