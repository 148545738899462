import React from 'react'
import { useTranslation } from 'react-i18next'
import { FooterLanguageSelector } from 'components/LanguageSelector'
import {
  FooterCopyright,
  FooterLanguageSection,
  FooterStyle,
  FooterTCLink,
  FooterTCSection,
} from './Footer.style'

const Footer = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const baseUrl = process.env.REACT_APP_FOOTER_DOMAIN
  const footerLinks = [
    { url: t('footer.links.TC_service', { domain: baseUrl }), name: t('footer.TC_service') },
    { url: t('footer.links.TC_site_use', { domain: baseUrl }), name: t('footer.TC_site_use') },
    {
      url: t('footer.links.privacy_policy', { domain: baseUrl }),
      name: t('footer.privacy_policy'),
    },
  ]

  return (
    <FooterStyle>
      <FooterLanguageSection>
        <FooterLanguageSelector />
      </FooterLanguageSection>
      <FooterTCSection>
        {footerLinks.map((link) => (
          <FooterTCLink key={link.name} href={link.url} target="_blank">
            {link.name}
          </FooterTCLink>
        ))}
      </FooterTCSection>
      <FooterCopyright>{t('footer.legal', { year: currentYear })}</FooterCopyright>
    </FooterStyle>
  )
}

export default Footer
