import axios from 'axios'

const baseURL = process.env.REACT_APP_PYW_API

const api = axios.create({
  baseURL,
  timeout: 60 * 5 * 1000,
})

export const get = ({ path, config }) => {
  return api.get(path, config)
}

export const post = ({ path, body, config }) => {
  return api.post(path, body, config)
}
