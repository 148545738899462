import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { colors } from 'globalStyles'

const StyledPopUp = styled(Modal)`
  .modal-header {
    background-color: ${colors.translucent_light_blue};
    padding: 0px;
    min-height: 78px;
    justify-content: center;
  }
  .modal-body {
    text-align: center;
    padding: 16px 16px 5px 16px;
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
    margin: 8px 0px 48px;
    padding: 0px;
  }
  .modal-title {
    font-weight: 600;
    font-size: 20px;
    padding: 15px;
  }
  .icon-padding {
    padding-right: 20px;
    width: 60px;
  }
  .title-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .modal-content {
    width: 100%;
  }
  /* div.fade.in.modal {
    display: flex !important;
  } */
`

const PopUp = ({
  children,
  title,
  icon,
  iconWidth,
  primaryButton, // primary
  secondaryButton, // secondary
  size,
  show,
  onHide,
}) => {
  const handleClose = () => onHide(false)
  const titleRef = useRef(null)
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus()
    }
  }, [])
  return (
    <StyledPopUp show={show} size={size} onHide={handleClose} backdrop="static" centered>
      {title !== null && (
        <StyledPopUp.Header>
          <StyledPopUp.Title autoFocus>
            <div className="title-text">
              <img className="icon-padding" src={icon} alt="icon-img" width={iconWidth} />
              <div ref={titleRef} tabIndex="-1">
                {title}
              </div>
            </div>
          </StyledPopUp.Title>
        </StyledPopUp.Header>
      )}
      <StyledPopUp.Body>{children}</StyledPopUp.Body>
      <StyledPopUp.Footer>
        {primaryButton} {secondaryButton}
      </StyledPopUp.Footer>
    </StyledPopUp>
  )
}

PopUp.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  primaryButton: PropTypes.node,
  secondaryButton: PropTypes.node,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
}

PopUp.defaultProps = {
  title: '',
  icon: '',
  iconWidth: '',
  primaryButton: null,
  secondaryButton: null,
  size: 'md',
  onHide: null,
}

export default PopUp
