import apigClientFactory from 'aws-api-gateway-client'

const awsAPICall = (config, pathParams, pathTemplate, method, headers, queryParams, body) => {
  const apigClient = apigClientFactory.newClient(config)

  const additionalParams = {
    headers,
    queryParams,
  }

  return apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
}

export default awsAPICall
