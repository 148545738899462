import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: window.location.pathname.split('/')[3],
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changLang: (state, action) => {
      state.lang = action.payload
    },
  },
})

export const { changLang } = globalSlice.actions

export default globalSlice.reducer
