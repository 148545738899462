import React, { useEffect, useCallback, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import PopUp from 'reusableComponents/PopUp'
import { Button, Spinner } from 'react-bootstrap'
import { InputField } from 'reusableComponents/InputField'
import { faUserCheck } from '@fortawesome/pro-regular-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { getDOMEncodeString, setCookieToLocalStorage } from 'utils/helperFunctions'
import {
  selectDeliverySummary,
  selectAddressCorrectionInfo,
} from 'store/shipping/shippingSelectors'
import { setIsVerifiedUser, setAllowRenewSession } from 'store/shipping/shippingSlice'
import FAIcon from 'reusableComponents/FAIcon'
import { colors } from 'globalStyles'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { verifyPostalCode, resendVerificationCode, verifyVerificationCode } from 'api/shipping'

const StyledLinkButton = styled(Button)`
  padding: 0 0.75rem;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
`

const StyledVerifyContent = styled.div`
  .form-floating {
    display: inline-block;
    margin: 7px 0;
    width: 295px;
  }
  .form-control.is-valid,
  .form-control.is-valid:focus {
    background-image: url(${getDOMEncodeString(
      <FAIcon icon={faCircleCheck} color={colors.primary_green} />
    )});
    border-color: ${colors.primary_black};
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
  .form-control.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
  .form-control {
    margin-bottom: 0;
  }
`

const isNumber = (char) => {
  return /^\d$/.test(char)
}

const isUpperLetter = (char) => {
  return /^[A-Z]+$/.test(char)
}

const isValidCanadianPostalCode = (code) => {
  return (
    isUpperLetter(code.charAt(0)) &&
    isUpperLetter(code.charAt(2)) &&
    isUpperLetter(code.charAt(4)) &&
    isNumber(code.charAt(1)) &&
    isNumber(code.charAt(3)) &&
    isNumber(code.charAt(5))
  )
}

const isValidUSPostalCode = (code) => {
  return /^\d{5}(-\d{4})?$/.test(code)
}

const getContent = (pcStatus, isUS, vcStatus, t, phoneNumber) => {
  switch (pcStatus) {
    case 0:
      return isUS
        ? t('verificationPopup.postal_code_message_us', { phoneNumber })
        : t('verificationPopup.postal_code_message', { phoneNumber })
    case 1:
      return isUS
        ? t('verificationPopup.postal_code_message_us', { phoneNumber })
        : t('verificationPopup.postal_code_message', { phoneNumber })
    case 2:
      return isUS
        ? t('verificationPopup.postal_code_not_found_message_us')
        : t('verificationPopup.postal_code_not_found_message')
    case 3:
      return isUS
        ? t('verificationPopup.postal_code_no_attempt_message_us')
        : t('verificationPopup.postal_code_no_attempt_message')
    case 4:
      switch (vcStatus) {
        case 0:
          return t('verificationPopup.verification_code_message', { phoneNumber })
        case 1:
          return t('verificationPopup.verification_code_message', { phoneNumber })
        case 2:
          return t('verificationPopup.verification_code_expired_message')
        case 3:
          return t('verificationPopup.verification_code_no_attempt_message')
        default:
          return null
      }
    default:
      return null
  }
}

const getPostalCodeErrorMessage = (pcStatus, isUS, t) => {
  switch (pcStatus) {
    case 1:
      return isUS
        ? t('verificationPopup.postal_code_invalid_error_us')
        : t('verificationPopup.postal_code_invalid_error')
    case 2:
      return isUS
        ? t('verificationPopup.postal_code_not_found_error')
        : t('verificationPopup.postal_code_not_found_error')
    case 3:
      return t('verificationPopup.no_attempt_error')
    default:
      return null
  }
}

const getVerificationCodeErrorMessage = (vcStatus, t) => {
  switch (vcStatus) {
    case 1:
      return t('verificationPopup.verification_code_invalid_error')
    case 2:
      return t('verificationPopup.verification_code_expired_error')
    case 3:
      return t('verificationPopup.no_attempt_error')
    default:
      return null
  }
}

const Verification = ({ show, onHide }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [postalCode, setPostalCode] = useState('')
  // 0: empty, 1: invalid, 2: not found, 3: zero attempts, 4: success
  const [postalCodeStatus, setPostalCodeStatus] = useState(0)
  const [postalCodeFailCount, setPostalCodeFailCount] = useState(0)
  const [verificationCode, setVerificationCode] = useState('')
  // 0: empty, 1: invalid, 2: expired, 3: zero attempts, 4: success
  const [verificationCodeStatus, setVerificationCodeStatus] = useState(0)
  const [verificationCodeFailCount, setVerificationCodeFailCount] = useState(0)
  // 0: disabled, 1: resend, 2: enabled
  const [resendIsDisabled, setResendIsDisabled] = useState(0)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { shipmentId, phoneNumber, hashedPin } = useSelector(selectDeliverySummary)
  const { toAddress } = useSelector(selectAddressCorrectionInfo)
  const country = _.get(toAddress, 'country', 'CA')

  const clearVerificationData = useCallback(() => {
    setPostalCode('')
    setPostalCodeStatus(0)
    setPostalCodeFailCount(0)
    setVerificationCode('')
    setVerificationCodeStatus(0)
    setVerificationCodeFailCount(0)
    setResendIsDisabled(0)
    setIsLoading(false)
  }, [])

  // setting timeouts
  useEffect(() => {
    if (postalCodeFailCount === 5) {
      const zeroAttemptsTimeout = setTimeout(() => {
        setPostalCodeFailCount(0)
        setPostalCodeStatus(0)
      }, 30000)

      return () => {
        clearTimeout(zeroAttemptsTimeout)
      }
    }

    if (verificationCodeFailCount === 5) {
      const zeroAttemptsTimeout = setTimeout(() => {
        setVerificationCodeFailCount(0)
        setVerificationCodeStatus(0)
      }, 30000)

      return () => {
        clearTimeout(zeroAttemptsTimeout)
      }
    }

    if (resendIsDisabled === 1) {
      const resendTimeout = setTimeout(() => {
        setResendIsDisabled(2)
      }, 30000)

      return () => {
        clearTimeout(resendTimeout)
      }
    }
    return null
  }, [postalCodeFailCount, verificationCodeFailCount, resendIsDisabled])

  const postalCodeChangeHandler = useCallback(
    (event) => {
      const isUS = country === 'US'
      const pc = event.target.value
      // only allow numbers and letters
      const re = isUS ? /^[0-9\b]+$/ : /^[0-9a-zA-Z\b]+$/
      if (pc !== '' && !re.test(pc)) return
      setPostalCode(pc)

      if (isUS ? pc.length < 5 : pc.length < 6) {
        setPostalCodeStatus(0)
        return
      }
      const postalCodeUpper = pc.toUpperCase()

      // check if Canadian postal code is valid
      const isValidPostalCode = isUS
        ? isValidUSPostalCode(postalCodeUpper)
        : isValidCanadianPostalCode(postalCodeUpper)
      if (isValidPostalCode) {
        setIsLoading(true)
        verifyPostalCode(shipmentId, postalCodeUpper, i18n.language)
          .then(() => {
            setPostalCodeStatus(4)
            setResendIsDisabled(2)
          })
          .catch(() => {
            setPostalCodeFailCount((prev) => {
              if (prev === 4) {
                setPostalCodeStatus(3)
                return 5
              }
              setPostalCodeStatus(2)
              return prev + 1
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        // eslint-disable-next-line no-console
        console.log('invalid postal code')
        setPostalCodeStatus(1)
      }
    },
    [country, i18n.language, shipmentId]
  )

  const verificationCodeChangeHandler = (event) => {
    const vc = event.target.value
    // only allow numbers
    const re = /^[0-9\b]+$/
    if (vc !== '' && !re.test(vc)) return
    setVerificationCode(vc)
    if (vc.length < 6) {
      setVerificationCodeStatus(0)
    }
  }

  const onActionVerifyHandler = useCallback(() => {
    if (verificationCodeFailCount === 5 || postalCodeStatus !== 4 || isVerifying) return
    if (verificationCode.length === 0) {
      setVerificationCodeStatus(1)
      return
    }
    setIsVerifying(true)
    verifyVerificationCode(shipmentId, verificationCode, i18n.language)
      .then((response) => {
        const data = response.data.verificationCodeData

        if (
          data.response === 'VERIFICATION_CODE_DOES_NOT_MATCH' ||
          data.response === '"VALIDATION_CODE_REQUIRED"'
        ) {
          setVerificationCodeStatus(1)
          setVerificationCodeFailCount((prev) => prev + 1)
        } else if (data.response === 'MAX_VERIFICATIONCODE_VERIFICATION_ATTEMPTS') {
          setVerificationCodeStatus(3)
          setVerificationCodeFailCount((prev) => prev + 1)
        } else if (data.response === 'VERIFICATION_CODE_DOES_NOT_EXISTS') {
          setVerificationCodeStatus(2)
        } else if (data.response === 'SESSION_CREATED') {
          setVerificationCodeStatus(4)
          const { sessionUUID, expiryTime, updateExpTime } = data
          setCookieToLocalStorage(hashedPin, sessionUUID, expiryTime)
          dispatch(setIsVerifiedUser(true))
          dispatch(setAllowRenewSession(updateExpTime))
          clearVerificationData()
          onHide()
        } else {
          // eslint-disable-next-line no-console
          console.log('Verification', 'verifiy verification code', 'unknown response')
          clearVerificationData()
          onHide()
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Verification', 'verifiy verification code sent failed due to: ', error)
        clearVerificationData()
        onHide()
      })
      .finally(() => {
        setIsVerifying(false)
      })
  }, [
    verificationCodeFailCount,
    postalCodeStatus,
    isVerifying,
    verificationCode,
    shipmentId,
    i18n.language,
    hashedPin,
    dispatch,
    clearVerificationData,
    onHide,
  ])

  const resendVerificationCodeHandler = useCallback(() => {
    setResendIsDisabled(1)

    resendVerificationCode(shipmentId, i18n.language)
      .then((response) => {
        const { data } = response

        if (data.response === 'MAX_VERIFICATIONCODE_RESEND_LIMIT') {
          setVerificationCodeStatus(3)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('resendVerificationCodeHandler error', error)
      })
  }, [i18n.language, shipmentId])

  const onActionCloseHandler = () => {
    clearVerificationData()
    onHide()
  }

  const spinnerStyle = {
    zIndex: '2',
    position: 'absolute',
    margin: '28px 0 0 -28px',
  }

  return (
    <PopUp
      title={t('verificationPopup.verification_title')}
      show={show}
      size="md"
      icon={getDOMEncodeString(<FAIcon icon={faUserCheck} color={colors.primary_black} />)}
      iconWidth="45px"
      primaryButton={
        <Button variant="secondary" onClick={onActionCloseHandler}>
          {t('verificationPopup.cancel_buttom')}
        </Button>
      }
      secondaryButton={
        <Button variant="primary" onClick={onActionVerifyHandler} disabled={postalCodeStatus !== 4}>
          {isVerifying ? (
            <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
          ) : (
            t('verificationPopup.verify_button')
          )}
        </Button>
      }
      onHide={onHide}
    >
      <StyledVerifyContent>
        <div className="sub-text" id="sub-text" aria-live="polite">
          {getContent(postalCodeStatus, country === 'US', verificationCodeStatus, t, phoneNumber)}
        </div>
        <InputField
          type="text"
          className="input-field"
          id="input-postal-code"
          label={
            country === 'US'
              ? t('verificationPopup.postal_code_placeholder_us')
              : t('verificationPopup.postal_code_placeholder')
          }
          isInvalid={postalCodeStatus !== 0 && postalCodeStatus !== 4}
          isValid={postalCodeStatus === 4}
          errorText={getPostalCodeErrorMessage(postalCodeStatus, country === 'US', t)}
          onChange={postalCodeChangeHandler}
          value={postalCode}
          maxLength={6}
          disabled={postalCodeFailCount === 5}
          autoComplete="off"
        />
        {isLoading ? (
          <Spinner
            style={spinnerStyle}
            size="sm"
            animation="border"
            role="status"
            variant="primary"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          ''
        )}
        <div id="postal-code-status" />
        <InputField
          type="text"
          className="input-field"
          id="input-veri-code"
          label={t('verificationPopup.verification_code_placeholder')}
          isInvalid={verificationCodeStatus !== 0 && verificationCodeStatus !== 4}
          isValid={verificationCodeStatus === 4}
          errorText={getVerificationCodeErrorMessage(verificationCodeStatus, t)}
          onChange={verificationCodeChangeHandler}
          value={verificationCode}
          maxLength={6}
          disabled={postalCodeStatus !== 4 || verificationCodeFailCount === 5}
          autoComplete="off"
        />
        <div>
          <span id="resend-text">{`${t('verificationPopup.send_again_message')} `}</span>
          <StyledLinkButton
            id="resend-code-link"
            className="button"
            variant="link"
            onClick={resendVerificationCodeHandler}
            disabled={
              resendIsDisabled !== 2 || postalCodeStatus !== 4 || verificationCodeStatus === 3
            }
          >
            {t('verificationPopup.send_again_button')}
          </StyledLinkButton>
        </div>
      </StyledVerifyContent>
    </PopUp>
  )
}

Verification.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
}

Verification.defaultProps = {
  onHide: null,
}

export default Verification
