import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { breakpoints } from 'globalStyles'
import TermsAndConditions from 'components/TermsAndConditions/'
import { SIGNATURE_PREFERENCE } from 'constants.json'

const SigReleaseRemoval = styled.div`
  .signAuthHeader {
    text-align: center;
  }
  .signReleaseContent {
    margin: 18px 15px 24px 13px;
    text-align: left;
    font-size: 12px;
    line-height: 19.5px;
  }

  @media ${breakpoints.desktop}, ${breakpoints.tablet} {
    .signAuthHeader {
      font-size: 20px;
      text-align: center;
    }

    .signReleaseContent {
      display: block;
      text-align: left;
      font-size: 14px;
      margin: 16px 30px 24px 32px;
    }
  }
`

const SignatureReleaseRemoval = ({ handleClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'signaturePreferences' })
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    handleClick(SIGNATURE_PREFERENCE.SIGNATURE_RELEASED)
  }

  return (
    <SigReleaseRemoval>
      <Form id="signForm" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="sigRemoval">
          <span className="signAuthHeader">{t('signRemovalHeader')}</span>
          <div className="signReleaseContent">
            <Trans t={t} i18nKey="signRemovalContent" components={[<TermsAndConditions />]} />
          </div>
        </Form.Group>
      </Form>
    </SigReleaseRemoval>
  )
}

SignatureReleaseRemoval.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default SignatureReleaseRemoval
