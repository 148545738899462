import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors } from 'globalStyles'
import pywBannerEn from 'assets/svg/PYW_Banner_EN.svg'
import pywBannerFr from 'assets/svg/PYW_Banner_FR.svg'
import FAIcon from 'reusableComponents/FAIcon'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  .txt-style {
    margin: 30px 30px 30px 30px;
    display: flex;
    gap: 10px;
    text-align: left;
  }
`

const ServiceUnavailable = styled.div`
   .title-image {
    width: 100%;
  }
  }
`

const ServiceUnavailableContent = styled.div`
  display: inline-flex, 
  align-items: center
  }
`
const CansServiceUnavailable = () => {
  const { i18n } = useTranslation()
  const { language: lang } = i18n
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ServiceUnavailable language={lang}>
        <img
          src={lang === 'en' ? pywBannerEn : pywBannerFr}
          alt="CANS Header"
          className="title-image"
        />
      </ServiceUnavailable>
      <ServiceUnavailableContent>
        <p className="txt-style">
          <FAIcon icon={faTriangleExclamation} color={colors.medium_orange} />
          {t('cansDoorKnocker.service_unavailable')}
        </p>
      </ServiceUnavailableContent>
    </Wrapper>
  )
}

export default CansServiceUnavailable
