const translationKey = 'deliveryPreferencesSummary.delivery_opt'
const locations = [
  { label: `${translationKey}.mailroom`, value: 'mailroom', enabled: true },
  {
    label: `${translationKey}.front_door`,
    value: 'front_door',
    enabled: true,
  },
  { label: `${translationKey}.rear_door`, value: 'rear_door', enabled: true },
  { label: `${translationKey}.garage`, value: 'garage', enabled: true },
  {
    label: `${translationKey}.leave_with_concierge`,
    value: 'leave_with_concierge_front_desk',
    enabled: true,
  },
  {
    label: `${translationKey}.basement_side_door`,
    value: 'basement_side_door',
    enabled: true,
  },
  { label: `${translationKey}.porch`, value: 'porch', enabled: true },
]

const businessLocations = [
  {
    label: `${translationKey}.shipping_dock`,
    value: 'shipping_dock',
    enabled: true,
  },
  {
    label: `${translationKey}.mailroom`,
    value: 'business_location_mailroom',
    enabled: true,
  },
  { label: `${translationKey}.reception`, value: 'reception', enabled: true },
]

export { locations, businessLocations }
